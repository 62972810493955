var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Id Karyawan",
      "placeholder": "System ID",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail' || _vm.props2 === 'Edit'
    },
    model: {
      value: _vm.props1.id,
      callback: function callback($$v) {
        _vm.$set(_vm.props1, "id", $$v);
      },
      expression: "props1.id"
    }
  }), _c('div', {
    staticClass: "marital-status flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Department ")]), _c('Dropdown', {
    attrs: {
      "options": _vm.departments,
      "optionLabel": "name",
      "default": _vm.department,
      "placeholder": "Choose employee's department",
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.department,
      callback: function callback($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Departemen",
      "placeholder": "Choose employee's departemen",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.department,
      callback: function callback($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Role",
      "placeholder": "Employee's role",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.role,
      callback: function callback($$v) {
        _vm.role = $$v;
      },
      expression: "role"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Grade",
      "placeholder": "Choose employee's grade",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.props1.email,
      callback: function callback($$v) {
        _vm.$set(_vm.props1, "email", $$v);
      },
      expression: "props1.email"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Level",
      "placeholder": "Choose employee's level",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.level,
      callback: function callback($$v) {
        _vm.level = $$v;
      },
      expression: "level"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Status",
      "placeholder": "Choose employee's status",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.activeEmployee,
      callback: function callback($$v) {
        _vm.activeEmployee = $$v;
      },
      expression: "activeEmployee"
    }
  }), _c('TextField', {
    attrs: {
      "type": "date",
      "label": "Join Date",
      "placeholder": "Employee's join date",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.props1.firstJoinDate,
      callback: function callback($$v) {
        _vm.$set(_vm.props1, "firstJoinDate", $$v);
      },
      expression: "props1.firstJoinDate"
    }
  }), _c('TextField', {
    attrs: {
      "type": "date",
      "label": "End Contract Date",
      "placeholder": "Employee's end contract date",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.props1.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.props1, "endDate", $$v);
      },
      expression: "props1.endDate"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }