<template>
  <div class="flex flex-col gap-6">
    <TextField type="text" label="Id Karyawan" placeholder="System ID" :borderEnabled="true" v-model="props1.id" :disabled="props2 === 'Detail' || props2 === 'Edit'" />
    <div class="marital-status flex flex-col gap-1">
      <label class="text-xs font-medium neutral-500 pl-4"> Department </label>
      <Dropdown :options="departments" optionLabel="name" v-model="department" :default="department" placeholder="Choose employee's department" :disabled="props2 === 'Detail'" />
    </div>
    <TextField type="text" label="Departemen" placeholder="Choose employee's departemen" :borderEnabled="true" v-model="department" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Role" placeholder="Employee's role" :borderEnabled="true" v-model="role" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Grade" placeholder="Choose employee's grade" :borderEnabled="true" v-model="props1.email" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Level" placeholder="Choose employee's level" :borderEnabled="true" v-model="level" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Status" placeholder="Choose employee's status" :borderEnabled="true" v-model="activeEmployee" :disabled="props2 === 'Detail'" />
    <TextField type="date" label="Join Date" placeholder="Employee's join date" :borderEnabled="true" v-model="props1.firstJoinDate" :disabled="props2 === 'Detail'" />
    <TextField type="date" label="End Contract Date" placeholder="Employee's end contract date" :borderEnabled="true" v-model="props1.endDate" :disabled="props2 === 'Detail'" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      departments: [
        {
          value: 2,
          name: 'Alliance + B2B'
        },
        {
          value: 3,
          name: 'B2C'
        },
        {
          value: 4,
          name: 'CEO'
        },
        {
          value: 5,
          name: 'Edu & Curriculum'
        },
        {
          value: 6,
          name: 'Finance Accounting Tax'
        },
        {
          value: 7,
          name: 'G2Lab'
        },
        {
          value: 8,
          name: 'HRD'
        },
        {
          value: 9,
          name: 'Legal, IR & Compliance'
        },
        {
          value: 10,
          name: 'Marketing'
        },
        {
          value: 11,
          name: 'Platform and Data Engineer'
        },
        {
          value: 12,
          name: 'Prakerja'
        }
      ]
    }
  },
  props: {
    props1: {
      type: Object,
      default: () => ({})
    },
    props2: {
      type: String
    }
  },
  computed: {
    department() {
      return this.props1.department.name || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.department.name : 'Data unavailable'
    },
    role() {
      return this.props1.role || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.role : 'Data unavailable'
    },
    level() {
      return this.props1.level || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.level : 'Data unavailable'
    },
    activeEmployee() {
      return this.props1.activeEmployee ? 'Active' : 'Non Aktif'
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Button" */ '@/components/Dropdown/Dropdown')
  }
}
</script>

<style></style>
